import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

function Projectpage({ project, lang }) {
  function modalNew(e) {
    const modal = document.getElementById('modal');
    let modalImage = document.querySelector('.modal__image');
    modal.style.display = 'flex';
    modalImage.src = e.target.src;
  }

  function modalClose() {
    const modal = document.getElementById('modal');
    modal.style.display = 'none';
  }

  let Category;
  project.category === 'arch'
    ? (Category = 'spatial design')
    : project.category === 'design'
    ? (Category = 'design')
    : project.category === 'graphic'
    ? (Category = 'graphical design')
    : (Category = '');

  return (
    <div>
      <div className='tabs'>
        <Link to='/' className='tabs__item tabs__item--back'>
          <FontAwesomeIcon
            className='tabs__arrow'
            icon={faArrowLeft}
            size='xs'
          />
          work
        </Link>
      </div>

      <div id='content' className='content'>
        <img
          onClick={modalNew}
          className='content__image content__image--main'
          src={process.env.PUBLIC_URL + `/img/${project.project}_01.jpg`}
          onError={(e) => {
            e.target.style = 'display: none';
          }}
          alt=''
        />
        <div className='content__header'>
          <h1 className='content__title'>
            {lang === 'nl' ? project.nl.title : project.en.title}
          </h1>
        </div>
        <div className='content__body'>
          <div className='content__subtitle-wrapper'>
            <h2 className='content__subtitle'>
              {lang === 'nl' ? 'categorie:' : 'category:'} {Category}
            </h2>
            <h2 className='content__subtitle'>
              {lang === 'nl'
                ? `typologie: ${project.nl.type}`
                : `typology: ${project.en.type}`}
            </h2>
            <h2 className='content__subtitle'>
              {lang === 'nl'
                ? `client: ${project.nl.client}`
                : `client: ${project.en.client}`}
            </h2>
            <h2 className='content__subtitle'>
              {lang === 'nl'
                ? `status: ${project.nl.status}`
                : `status: ${project.en.status}`}
            </h2>
          </div>
          <div className='content__text-wrapper'>
            <p className='content__text'>
              {lang === 'nl' ? project.nl.content : project.en.content}
            </p>
          </div>
        </div>
        <div className='content__image-wrapper'>
          <img
            onClick={modalNew}
            className='content__image content__image--secondary'
            src={process.env.PUBLIC_URL + `/img/${project.project}_02.jpg`}
            onError={(e) => {
              e.target.style = 'display: none';
            }}
            alt=''
          />
          <img
            onClick={modalNew}
            className='content__image content__image--secondary'
            src={process.env.PUBLIC_URL + `/img/${project.project}_03.jpg`}
            onError={(e) => {
              e.target.style = 'display: none';
            }}
            alt=''
          />
          <img
            onClick={modalNew}
            className='content__image content__image--secondary'
            src={process.env.PUBLIC_URL + `/img/${project.project}_04.jpg`}
            onError={(e) => {
              e.target.style = 'display: none';
            }}
            alt=''
          />
          <img
            onClick={modalNew}
            className='content__image content__image--secondary'
            src={process.env.PUBLIC_URL + `/img/${project.project}_05.jpg`}
            onError={(e) => {
              e.target.style = 'display: none';
            }}
            alt=''
          />
          <img
            onClick={modalNew}
            className='content__image content__image--secondary'
            src={process.env.PUBLIC_URL + `/img/${project.project}_06.jpg`}
            onError={(e) => {
              e.target.style = 'display: none';
            }}
            alt=''
          />
          <img
            onClick={modalNew}
            className='content__image content__image--secondary'
            src={process.env.PUBLIC_URL + `/img/${project.project}_07.jpg`}
            onError={(e) => {
              e.target.style = 'display: none';
            }}
            alt=''
          />
          <img
            onClick={modalNew}
            className='content__image content__image--secondary'
            src={process.env.PUBLIC_URL + `/img/${project.project}_08.jpg`}
            onError={(e) => {
              e.target.style = 'display: none';
            }}
            alt=''
          />
          <img
            onClick={modalNew}
            className='content__image content__image--secondary'
            src={process.env.PUBLIC_URL + `/img/${project.project}_09.jpg`}
            onError={(e) => {
              e.target.style = 'display: none';
            }}
            alt=''
          />
          <img
            onClick={modalNew}
            className='content__image content__image--secondary'
            src={process.env.PUBLIC_URL + `/img/${project.project}_10.jpg`}
            onError={(e) => {
              e.target.style = 'display: none';
            }}
            alt=''
          />
        </div>
      </div>

      <div id='modal' className='modal' onClick={modalClose}>
        <div className='modal__content'>
          <div className='modal__slide'>
            <img id='modal__image' className='modal__image' src='' alt='' />
          </div>
        </div>
        <div className='modal__buttons'>
          {/*<button
            id='modal__prev'
            className='modal__button modal__button--prev'
          >
            &#10094;
          </button>
          <button
            id='modal__next'
            className='modal__button modal__button--next'
          >
            &#10095;
          </button> */}
          <button
            onClick={modalClose}
            id='modal__close'
            className='modal__button modal__button--close'
          >
            &times;
          </button>
        </div>
      </div>
    </div>
  );
}

export default Projectpage;

/*
const image = document.querySelectorAll('.content__image');
const modalImage = document.querySelector('.modal__image');
const modalClose = document.getElementById('modal__close');
const modalPrev = document.getElementById('modal__prev');
const modalNext = document.getElementById('modal__next');
let slideIndex = 0;

if (modalClose) {
  modalClose.addEventListener('click', () => {
    modal.style.display = 'none';
    console.log('test');
  });
}

if (modalPrev) {
  modalPrev.addEventListener('click', () => {
    slideIndex--;
    if (slideIndex < 0) {
      slideIndex = image.length - 1;
    }
    console.log(slideIndex);
    console.log(image[slideIndex]);
    modalImage.src = image[slideIndex].src;
  });
}

if (modalNext) {
  modalNext.addEventListener('click', () => {
    slideIndex++;
    if (slideIndex >= image.length) {
      slideIndex = 0;
    }
    console.log(slideIndex);
    console.log(image[slideIndex]);
    modalImage.src = image[slideIndex].src;
  });
}

image.forEach((e, idx) => {
  e.addEventListener('click', () => {
    modal.style.display = 'flex';
    slideIndex = idx;
    modalImage.src = e.src;
    console.log(slideIndex);
  });
});

<div id='modal' className='modal'>
  <div className='modal__content'>
    <div className='modal__slide'>
      <img id='modal__image' className='modal__image' src='' alt='' />
    </div>
  </div>
  <div className='modal__buttons'>
    <button id='modal__prev' className='modal__button modal__button--prev'>
      &#10094;
    </button>
    <button id='modal__next' className='modal__button modal__button--next'>
      &#10095;
    </button>
    <button
      onClick={modalClose}
      id='modal__close'
      className='modal__button modal__button--close'
    >
      &times;
    </button>
  </div>
</div>;


.modal {
  z-index: 60;
  background-color: black;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  display: none;
  justify-content: center;
}
.modal__content {
  display: flex;
  justify-content: center;
  position: relative;
}
.modal__slide {
  height: 100vh;
  display: flex;
  align-items: center;
}
.modal__image {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}
.modal__buttons {
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1220px;
}
.modal__button {
  background-color: rgba(0, 0, 0, 0.3);
  border: none;
  text-decoration: none;
  color: white;
}
.modal__button--close {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 2.5em;
  margin: 0;
  padding: 0 7px;
  cursor: zoom-out;
}
.modal__button--prev {
  font-size: 2em;
  margin: 0;
  padding: 3px 5px;
  cursor: pointer;
}
.modal__button--next {
  font-size: 2em;
  margin: 0;
  padding: 3px 5px;
  cursor: pointer;
}
*/
