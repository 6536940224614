import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { faPinterest } from '@fortawesome/free-brands-svg-icons';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';

function Contact() {
  return (
    <div>
      <div className='tabs'>
        <Link to='/' className='tabs__item tabs__item--back'>
          <FontAwesomeIcon
            className='tabs__arrow'
            icon={faArrowLeft}
            size='xs'
          />
          work
        </Link>
      </div>

      <div className='contact'>
        <h1 className='contact__title'>contact</h1>
        <div className='contact__wrapper'>
          <h2 className='contact__info'>studio aabel</h2>
          <div className='contact__info contact__info-mail'>
            <a href='mailto:info@aabel.nl'>info@aabel.nl</a>
          </div>
          <div className='contact__info contact__info-phone'>
            <a href='tel:+31619005234'>tel: +31 619005234</a>
          </div>
          <div className='contact__social'>
            <a href='https://nl.pinterest.com/studioaabel/'>
              <FontAwesomeIcon
                className='contact__socialicon'
                icon={faPinterest}
              />
            </a>
            <a href='https://www.instagram.com/studioaabel/'>
              <FontAwesomeIcon
                className='contact__socialicon'
                icon={faInstagram}
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contact;
