import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPinterest } from '@fortawesome/free-brands-svg-icons';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';

function Overlay(props) {
  /* function language_en() {
    props.lang_en();
    removeOverlay();
  }

  function language_nl() {
    props.lang_nl();
    removeOverlay();
  } */

  function removeOverlay() {
    const navItem = document.getElementById('nav__item');
    const overlay = document.getElementById('overlay');
    overlay.classList.remove('active');
    navItem.classList.remove('active');
  }

  return (
    <div id='overlay' className='overlay'>
      <div id='overlay__body' className='overlay__body'>
        <div className='overlay__top'></div>
        <div className='overlay__content'>
          <Link to='/' onClick={removeOverlay}>
            work
          </Link>
          <Link to='/contact' onClick={removeOverlay}>
            contact
          </Link>
          {/* <div className='overlaylanguage'>
            <button
              onClick={language_en}
              id='overlaylanguage__en'
              className='overlaylanguage__button overlaylanguage__font'
            >
              en
            </button>
            <div className='overlaylanguage__font'> | </div>
            <button
              onClick={language_nl}
              id='overlaylanguage__nl'
              className='overlaylanguage__button overlaylanguage__font'
            >
              nl
            </button>
          </div> */}
        </div>

        <div className='overlayfooter'>
          <div className='overlayfooter__contact overlayfooter__contact-mail'>
            <a href='mailto:info@aabel.nl'>info@aabel.nl</a>
          </div>
          <div className='overlayfooter__contact overlayfooter__contact-phone'>
            <a href='tel:+31619005234'>tel: +31 619005234</a>
          </div>
          <div className='overlayfooter__social'>
            <a href='https://nl.pinterest.com/studioaabel/'>
              <FontAwesomeIcon
                className='overlayfooter__socialicon'
                icon={faPinterest}
              />
            </a>
            <a href='https://www.instagram.com/studioaabel/'>
              <FontAwesomeIcon
                className='overlayfooter__socialicon'
                icon={faInstagram}
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Overlay;
