import { Routes, Route } from 'react-router-dom';
import GalleryItem from './GalleryItem';
import Contact from './Contact';
import Projectpage from './Projectpage';
import ProjectsData from '../data/ProjectsData';
// import { useState, useEffect } from 'react';

function ProjectsGallery(props) {
  return (
    <div>
      <Routes>
        <Route
          path='/'
          element={<GalleryItem proj={ProjectsData} lang={props.lang} />}
        />
        {ProjectsData.map((e) => (
          <Route
            key={e.id}
            path={`/${e.project}`}
            element={<Projectpage project={e} lang={props.lang} />}
          />
        ))}
        <Route path='/contact' element={<Contact />} />
      </Routes>
    </div>
  );
}

export default ProjectsGallery;

/* const [ProjectsData, setProjectsData] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const response = await fetch('http://localhost:5000/projectsdata');
    const data = await response.json();
    setProjectsData(data);
    console.log(ProjectsData);
  }; */
