import { useState } from 'react';
// import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Header from './components/Header';
import Overlay from './components/Overlay';
import ProjectsGallery from './components/ProjectsGallery';
import Footer from './components/Footer';

function App() {
  const location = useLocation();
  const [Language, setLanguage] = useState('en');

  /* useEffect(() => {
    loadLanguage();
  }, []);

  function loadLanguage() {
    if (window.navigator.language === 'nl-NL') {
      setLanguage('nl');
    }
  } */

  function Language_en() {
    setLanguage('en');
  }

  function Language_nl() {
    setLanguage('nl');
  }

  return (
    <div id='container'>
      <Overlay lang_nl={Language_nl} lang_en={Language_en} />
      <main>
        <Header lang_nl={Language_nl} lang_en={Language_en} />
        <ProjectsGallery lang={Language} />
        {location.pathname !== '/contact' && <Footer />}
      </main>
    </div>
  );
}

export default App;

// npm i -D react-router-dom@latest

// npm i --save @fortawesome/fontawesome-svg-core
// npm i --save @fortawesome/free-solid-svg-icons
// npm i --save @fortawesome/free-regular-svg-icons
// npm i --save @fortawesome/free-brands-svg-icons
// npm i --save @fortawesome/react-fontawesome@latest

// optional:
// npm install babel-plugin-macros
// create a babel.config.js
/* module.exports = function (api) {
  return {
    plugins: ['macros'],
  }
}     */
// create a babel-plugin-macros.config.js
/* module.exports = {
  'fontawesome-svg-core': {
    'license': 'free'
  }
}
*/

// npm install -g json-server
// json-server --watch db.json --port 5000
