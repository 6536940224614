import '../index.css';
import { Link } from 'react-router-dom';
import { useEffect } from 'react';

let galleryColumns = 4;
let galleryRatio = 1.25;
let galleryWidth;
// let galleryHeigth;
let galleryItemHeight;
let galleryItemWidth;
// button highlight;

function refresh() {
  updateGallery();
  updateButtonHighlight();
}

function buttonHighlight(e) {
  let button = document.querySelectorAll('.button');
  button.forEach((e) => {
    e.classList.remove('active');
  });
  e.target.classList.add('active');
  updateButtonHighlight();
  if (e.target.id === 'btn2') {
    galleryCategory('gallery__item--arch');
  } else if (e.target.id === 'btn3') {
    galleryCategory('gallery__item--design');
  } else if (e.target.id === 'btn4') {
    galleryCategory('gallery__item--graphic');
  } else {
    galleryCategory('gallery__item');
  }
}
function updateButtonHighlight() {
  let button = document.querySelectorAll('.button');
  const highlight = document.getElementById('button-highlight');
  let x;
  let y;
  let z = document.querySelector('.button').getBoundingClientRect().left;
  for (let i = 0; i < button.length; i++) {
    if (button[i].classList.contains('active')) {
      x = button[i].getBoundingClientRect().left;
      y = button[i].getBoundingClientRect().right;
    }
  }
  highlight.style.marginLeft = `${x - z}px`;
  highlight.style.width = `${y - x}px`;
}

// gallery selection;

function galleryCategory(e) {
  let galleryItem = document.querySelectorAll('.gallery__item');
  for (let i = 0; i < galleryItem.length; i++) {
    if (galleryItem[i].classList.contains(e)) {
      galleryItem[i].classList.add('active');
    } else galleryItem[i].classList.remove('active');
    updateGallery();
  }
}

function gallerySize() {
  let gallery = document.getElementById('gallery');
  let galleryItem = document.querySelectorAll('.gallery__item');

  if (window.matchMedia('(min-width: 1140px)').matches) {
    galleryColumns = 3;
  } else if (window.matchMedia('(min-width: 640px)').matches) {
    galleryColumns = 2;
  } else {
    galleryColumns = 1;
  }
  galleryWidth = gallery.getBoundingClientRect().width;
  galleryItemWidth = galleryWidth / galleryColumns;
  galleryItemHeight = galleryRatio * galleryItemWidth;
  galleryItem.forEach((z) => {
    z.style.width = `${
      galleryItemWidth -
      parseFloat(window.getComputedStyle(galleryItem[0]).marginLeft) -
      parseFloat(window.getComputedStyle(galleryItem[0]).marginRight)
    }px`;
    z.style.height = `${
      galleryItemHeight -
      parseFloat(window.getComputedStyle(galleryItem[0]).marginTop) -
      parseFloat(window.getComputedStyle(galleryItem[0]).marginBottom)
    }px`;
  });
}

function updateGallery() {
  let gallery = document.getElementById('gallery');
  let galleryItem = document.querySelectorAll('.gallery__item');
  gallerySize();
  let j = 0;
  let k = 0;
  let l = 0;
  for (let i = 0; i < galleryItem.length; i++) {
    if (galleryItem[i].classList.contains('active')) {
      galleryItem[i].style.left = `${
        (k - j * galleryColumns) * (100 / galleryColumns)
      }%`;
      galleryItem[i].style.top = `${j * galleryItemHeight}px`;
      k++;
      if (k % galleryColumns === 0) {
        j++;
      }
      if ((k + (galleryColumns - 1)) % galleryColumns === 0) {
        l++;
      }
      gallery.style.height = `${l * galleryItemHeight}px`;
      galleryItem[i].style.transform = 'scale(1)';
      galleryItem[i].style.opacity = '1';
    } else if (galleryItem[i].classList.contains('active') === false) {
      galleryItem[i].style.transform = 'scale(0)';
      galleryItem[i].style.opacity = '0';
    }
  }
}

function GalleryItem({ proj, lang }) {
  useEffect(() => {
    refresh();
    window.addEventListener('resize', refresh);
    return () => {
      window.removeEventListener('resize', refresh);
    };
  }, []);

  return (
    <div>
      <div className='tabs'>
        <div className='tabs__list'>
          <button
            onClick={buttonHighlight}
            id='btn1'
            className='tabs__item button active'
          >
            all
          </button>
          <button
            onClick={buttonHighlight}
            id='btn2'
            className='tabs__item button'
          >
            spaces
          </button>
          <button
            onClick={buttonHighlight}
            id='btn3'
            className='tabs__item button'
          >
            objects
          </button>
          <button
            onClick={buttonHighlight}
            id='btn4'
            className='tabs__item button'
          >
            graphics
          </button>
          <div id='button-highlight'></div>
        </div>
      </div>

      <div id='gallery' className='gallery'>
        {proj.map((projects) => (
          <div
            key={projects.id}
            className={`gallery__item gallery__item--${projects.category} active`}
          >
            <Link to={`/${projects.project}`} className='gallery__link'>
              <img
                className='gallery__image'
                src={
                  process.env.PUBLIC_URL + `/img/${projects.project}_thumb.jpg`
                }
                alt='project not loaded'
              />
              <div className='gallery__name'>
                {lang === 'nl' ? projects.nl.title : projects.en.title}
              </div>
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
}
export default GalleryItem;

/*
 <div id='gallery' className='gallery' onLoad={refresh}>
*/
