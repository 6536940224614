import aabel_logo from '../images/aabel_logo.svg';
import { Link } from 'react-router-dom';

function Header(props) {
  function overlay() {
    const navItem = document.getElementById('nav__item');
    const overlay = document.getElementById('overlay');
    overlay.classList.toggle('active');
    navItem.classList.toggle('active');
  }

  return (
    <header>
      <div className='header'>
        {/* <div className='language '>
          <button
            onClick={props.lang_en}
            id='language__en'
            className='language__button language__font'
          >
            en
          </button>
          <div className='language__font'> | </div>
          <button
            onClick={props.lang_nl}
            id='language__nl'
            className='language__button language__font'
          >
            nl
          </button>
        </div> */}
        <div className='logo'>
          <Link
            to='/'
            id='logo__item'
            className='logo__item'
            style={{
              backgroundImage: `url(${aabel_logo}`,
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'contain',
            }}
          >
            {' '}
          </Link>
        </div>
        <div id='nav' className='nav'>
          <button onClick={overlay} id='nav__item' className='nav__item'>
            <div className='nav__bar nav__bar-top'></div>
            <div className='nav__bar nav__bar-bottom'></div>
          </button>
        </div>
      </div>
    </header>
  );
}

export default Header;
